exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-download-tsx": () => import("./../../../src/pages/download.tsx" /* webpackChunkName: "component---src-pages-download-tsx" */),
  "component---src-pages-eula-tsx": () => import("./../../../src/pages/eula.tsx" /* webpackChunkName: "component---src-pages-eula-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-privacy-tsx": () => import("./../../../src/pages/privacy.tsx" /* webpackChunkName: "component---src-pages-privacy-tsx" */),
  "component---src-pages-ref-lyne-sudbury-tsx": () => import("./../../../src/pages/ref/lyne_sudbury.tsx" /* webpackChunkName: "component---src-pages-ref-lyne-sudbury-tsx" */),
  "component---src-pages-support-tsx": () => import("./../../../src/pages/support.tsx" /* webpackChunkName: "component---src-pages-support-tsx" */),
  "component---src-pages-waitifylock-tsx": () => import("./../../../src/pages/waitifylock.tsx" /* webpackChunkName: "component---src-pages-waitifylock-tsx" */)
}

